<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div>
    <!-- USER ROLE LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4>User Roles</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="
                flex flex-wrap
                items-center
                justify-between
                ag-grid-table-actions-right
              "
            >
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
              <!-- <vs-button
                class="mb-4 md:mb-0 text-hover-white"
                @click="gridApi.exportDataAsCsv()"
              >Export as CSV</vs-button>-->
              <vx-tooltip text="Export Excel">
                <a
                  :href="
                    excelurl +
                    `/v1/userrole/excel/download?organization_id=${organization_id}&token=${temp_token}&search=${searchQuery}`
                  "
                  target="_blank"
                  v-if="this.archive != 'archive'"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>

              <vx-tooltip text="Export Archive Excel">
                <a
                  :href="
                    excelurl +
                    `/v1/userrole/excel/download?organization_id=${organization_id}&token=${temp_token}&archive=1&search=${searchQuery}`
                  "
                  target="_blank"
                  v-if="this.archive == 'archive'"
                >
                  <vs-button class="px-4">
                    <span class="d-inline-flex pr-5 text-white">Export</span>

                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowClicked="onRowDataChanged"
          :rowData="roles"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
        ></ag-grid-vue>
        <!-- :pagination="true"
          :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"-->
        <!-- <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" /> -->
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
const VxTour = () => import("@/components/VxTour.vue");
import UserRolesService from "@/services/userRolesService.js";

import userRoleStatus from "./userRoleStatus.vue";
import { BASEURL } from "@/config/index.js";
import EditUserRole from "./EditUserRole.vue";

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
    "switch-component": {
      template: `<span>sjd</span>`,
    },
  },
  data() {
    return {
      roles: [],
      excelurl: BASEURL(),
      User: "Select User",
      switchValue: true,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
      columnDefs: [],
    };
  },
  beforeMount() {
    eventBus.$on("refreshTablerole", (e) => {
      this.getUserRoleList(this.currentPage);
    });
    this.columnDefs = [
      // {
      //   headerName: "Sr.No.",
      //   field: "firstname",
      //   width: 150,
      //   filter: true,
      //   checkboxSelection: true,
      //   headerCheckboxSelectionFilteredOnly: true,
      //   headerCheckboxSelection: true
      // },
      {
        headerName: "Role Name",
        field: "role_name",
        filter: false,
        width: 1440,
      },
      // {
      //   headerName: "Organization",
      //   field: "organization_name",
      //   filter: true,
      //   width: 475,
      // },
      {
        headerName: "Status",
        field: "",
        filter: false,
        width: 120,
        hide: true,
        //         hide: this.archive == "archive" ? true : false,

        cellStyle: { "text-align": "center" },
        cellRendererFramework: userRoleStatus,
      },
      {
        headerName: "Edit",
        field: "",
        filter: false,
        width: 80,
        hide: true,

        // hide: this.archive == "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: EditUserRole,
      },
      {
        headerName: this.archive == "archive" ? "Unarchive" : "Archive",
        field: "",
        filter: false,
        width: 120,
        hide: true,

        cellStyle: { "text-align": "center" },
        cellRenderer: function (params) {
          return '<span class="text-center table-edit-icon"><i class="text-center material-icons">archive</i></span>';
        },
      },
    ];
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (this.gridApi) return this.gridApi.paginationGoToPage(val - 1);
        else return 1;
      },
    },
  },
  methods: {
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    onChangeStatus: function () {
      console.log("object");
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getUserRoleList: function () {
      let payload = {
        limit: this.paginationPageSize,
      };
      UserRolesService.getAllUserRoles(payload)
        .then((response) => {
          const { data } = response;
          if (!data.err) {
            this.roles = data.data;
            this.gridApi.sizeColumnsToFit();
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getUserRoleList();
    this.gridApi = this.gridOptions.api;
  },
};
</script>
