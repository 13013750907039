
<template>
  <div>
    <!-- <label class="switch">
      <input type="checkbox" @click="popupActive=true" :checked="value == '1'" />
      <span class="slider round"></span>
    </label>-->

    <b-form-checkbox
      @click.native="popupActive=true"
      value="1"
      unchecked-value="2"
      v-model="form.userrole_active"
      switch
      class="table-status-icon"
    ></b-form-checkbox>

    <div class="demo-alignment">
      <vs-popup
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Role Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5>Do you want to change Status ?</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button color="success" type="filled" @click="onChangeCheckbox()" class="mr-3">Yes</vs-button>
              <vs-button color="danger" type="filled" @click="privStatus()">No</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import userRolesService from "@/services/userRolesService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        userrole_active: "1"
      },
      copy_userrole_active: null
    };
  },
  beforeMount() {
    this.form = this.params.data;
    this.copy_userrole_active = this.form.userrole_active;
  },
  methods: {
    onChangeCheckbox: function() {
      this.$vs.loading();
      // this.value = this.value === "1" ? "2" : "1";
      let id = this.params.data.role_id;
      let payload = {
        // employment_name: this.params.data.employment_name,
        // company_id: this.params.data.employment_id,
        // description: this.params.data.description,
        userrole_active: this.form.userrole_active
      };
      console.log("payload, id", payload, id);
      userRolesService
        .editUserRole(payload, id)
        .then(response => {
          this.$vs.loading.close();
          const { data } = response;
          if (!data.error||data.Status == true ) {
            this.$vs.notify({
              title: "Updated!",
                                               text: data.Message,

              iconPack: "feather",
              icon: "check_box",
              color: "success"
            });
            this.popupActive = false;
          } else {
            this.$vs.loading.close();
            console.log("data :", data);
            this.$vs.notify({
              title: "Error!",
                                                text: data.Message,

              iconPack: "feather",
              icon: "check_box",
              color: "warning"
            });
          }
        })
        .catch(err => {
          this.$vs.loading.close();
        });
    },
    privStatus: function() {
      this.popupActive = false;
      this.form.userrole_active = this.copy_userrole_active;
    }
  }
});

// import Vue from "vue";

// export default Vue.extend({
//   template: ` {{params.value}}
//          <label class="switch">
//           <input @click="onChangeCheckbox" type="checkbox">
//           <span class="slider round"></span>
//     </label> `,
//   data: function () {
//     return {};
//   },
//   beforeMount() {},
//   mounted() {},
//   methods: {

//   }
// });
</script>